.Layout {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 7% 87% 6%;
}

header {
    background: linear-gradient(180deg, rgb(0, 0, 0), rgb(70, 70, 70));
    grid-column: 1 / span 6;
    grid-row: 1;
    z-index: 999;
}


main {
    width: 100%;
    grid-column: 1 /span 6;
    grid-row: 2;
    overflow-y: auto;
    scroll-behavior: smooth;
    direction: ltr;
    background-color: #fff;
}

footer {
    grid-column: 1 / span 6;
    grid-row: 3;
    background-color: rgb(102, 99, 95);
    border-top: 1px solid #000;
}