.Header{
    display: flex;
    align-items: center;
    margin: 0 50px;

}
.logo{
    width: 32%;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 35px;
}
.menu{
    width: 100%;
    display: flex;
    align-items: center;
    color: #fff
}
.menu a{
    margin: 0 20px;
}

.menu a, .logo a{
    color: #fff
}
.menu a:hover, .logo a:hover{ 
    transform: scale(1.2);
    color: #fff;
    text-decoration: none;
}

.authMenu{
    width: -webkit-fill-available;
    display: flex;
    align-items: center;

}