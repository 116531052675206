

.AuthMenu {
    font-size: 15px;
    width: 100%;
    color: #fff;
    margin: 15px 0 15px 0;
    display: flex;
    justify-content: flex-end;
}

.login a {
    text-decoration: none;
    border-radius: 10px;
    color: #fff;
    margin-left: 8px;
    padding: 10px;
}

a.login:hover {
    text-decoration: none;
    color: rgb(255, 255, 255);
    transform: scale(1.2);
}
.AuthMenu a{
    text-decoration: none;
    color: #fff;
    margin: 0 10px;
}
.AuthMenu a:hover{
    text-decoration: none;
    color: #fff;
    margin: 0 10px;
    transform: scale(1.2);
}
