@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@300&display=swap');

html, body, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  color: rgb(0, 0, 0);
  background: rgb(255, 255, 255);
}

a{
  text-decoration: none;
}

.trnsBg{
  background-color: rgba(255, 255, 255, 0.6);
  padding: 10px;
  margin: 10px;
}